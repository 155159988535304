import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Popover from "components/Popover";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "popover"
    }}>{`Popover`}</h1>

    <Playground __position={0} __code={'() => {\n  const [popoverOpen, setPopoverOpen] = React.useState(false)\n  const toggle = () => setPopoverOpen(!popoverOpen)\n  return (\n    <>\n      <a id=\"Popover1\" className=\"btn btn-primary\">\n        Launch Popover\n      </a>\n      <Popover\n        placement=\"bottom\"\n        isOpen={popoverOpen}\n        target=\"Popover1\"\n        toggle={toggle}\n      >\n        <Popover.Header>Popover Title</Popover.Header>\n        <Popover.Body>\n          Sed posuere consectetur est at lobortis. Aenean eu leo quam.\n          Pellentesque ornare sem lacinia quam venenatis vestibulum.\n        </Popover.Body>\n      </Popover>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Popover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [popoverOpen, setPopoverOpen] = React.useState(false);

        const toggle = () => setPopoverOpen(!popoverOpen);

        return <>
        <a id="Popover1" className="btn btn-primary">
          Launch Popover
        </a>
        <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle} mdxType="Popover">
          <Popover.Header>Popover Title</Popover.Header>
          <Popover.Body>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</Popover.Body>
        </Popover>
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/popovers/" target="_blank">Reactstrap Popover</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      